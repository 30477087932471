@use "variables" as *;
@use "animation";

html {
  background-color: $bg-color;
  padding: 0;
  margin: 0;
  scroll-snap-type: y mandatory;
  overflow-x: hidden;
  // -webkit-overflow-scrolling: touch;
  // overflow-y: visible;
  // overflow-x: hidden;
}

.indicators {
  height: 8rem;
  width: max-content;
  position: -webkit-sticky;
  position: sticky;
  top: 2rem;
  left: 0;
  z-index: 100;
  transition: all 0.2s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  &:hover a {
    transition: all 0.2s ease;
    width: 100%;
  }

  &__sections {
    display: flex;
    align-items: center;

    &:hover a {
      margin: 0 0 0 1.2rem;
      // font: normal 500 1rem $Noto;
      font-weight: 700;
    }

    a {
      transition: all 0.2s ease;
      width: 0rem;
      overflow: hidden;
      text-transform: capitalize;
      text-decoration: none;
      color: black;
    }
  }
  &__lines {
    background-color: black;
    height: 1px;
    width: 1.5rem;
    margin: 0 3px 0 0;
    pointer-events: none;
  }
}

.landing {
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $landing-text-color;
  scroll-snap-align: start;
  // z-index: 101;

  h1 {
    width: 8vh;
    font: normal 700 5vh $DMDisplay;
    text-transform: uppercase;
    position: absolute;
    top: 5vh;
    left: 40vw;
    z-index: 1;
  }

  p {
    z-index: 1;
    font: normal 400 1.7vh $Lora;
    position: absolute;
    bottom: 25vh;
    margin: auto;
    padding-left: 5vw;
    min-width: 15rem;
    max-width: 25vw;
  }

  img {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
  }
}

.project {
  &__container {
    // height: 100vh;
    width: 100vw;
    position: relative;
    top: -8rem;
  }

  &__section {
    width: 100vw;
    position: relative;
    display: flex;
    scroll-snap-align: start;
    scroll-snap-stop: always;
  }

  &__content {
    // min-height: 800px;
    flex-grow: 1;
    height: 100vh;
  }

  &__title {
    font: normal 400 6rem $DMDisplay;
    transform-origin: 0 0;
    transform: rotate(-90deg);
    height: max-content;
    word-break: keep-all;
    width: 100vh;
    display: flex;
    margin: 0;

    &--container {
      transform: translateY(97vh);
      align-self: flex-start;
      width: 10rem;
      height: 8vh;
    }
  }

  &__image {
    margin: 5rem 5rem 4rem 0rem;
    overflow: hidden;
    max-height: 65vh;
    max-width: 85vw;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &--bg {
      width: 100%;
      object-fit: cover;
    }
    &--logo {
      position: absolute;
      height: 50%;
      z-index: 10;
    }
  }

  &__textBlock {
    max-width: 25rem;
    margin: 0 0 0 0rem;

    p {
      min-height: 7rem;
    }
  }
  &__techStacks {
    display: flex;
    flex-wrap: wrap;
    margin: 2vh 0 0 0;

    div {
      margin-right: 1rem;
      color: gray;
    }
  }

  &__links {
    display: flex;
    align-items: flex-end;
    text-align: center;
    padding: 2rem;
    flex: 0.8;
    a {
      text-decoration: none;
      margin-right: 1rem;
      color: black;
      font: normal 700 1.2rem $Noto;
      text-transform: uppercase;
      flex: 1;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.resume {
  height: 100vh;
  width: 100vw;
  scroll-snap-align: start;

  &__title {
    display: none;
  }

  &__download {
    height: 70vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;

    a {
      margin: 0 1rem;
      text-decoration: none;
      color: black;
      font: normal 400 3rem $DMDisplay;
      text-transform: uppercase;
      letter-spacing: 1rem;
    }

    div {
      height: 1px;
      width: 2rem;
      background-color: black;
    }

    &--icon {
      width: 2.1rem;
      height: 2.1rem;
    }
  }

  &__cred {
    display: flex;
    justify-content: space-around;
  }

  &__list {
    h2 {
      font: normal 600 1rem $Noto;
      text-transform: capitalize;
    }

    ul {
      padding: 0;

      li {
        font: normal 400 0.8rem $Noto;
        text-transform: capitalize;
        list-style: none;
        text-align: left;
        margin-bottom: 0.5rem;

        a {
          text-decoration: none;
          color: black;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  #downloadMobile {
    display: none;
  }
}
