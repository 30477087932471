@use "variables";
$breakpoint-mobile: 700px;
$breakpoint-md: 1500px;
$breakpoint-lg: 2000px;

@media only screen and (max-width: $breakpoint-mobile) {
  .indicators {
    top: 1rem;

    .show {
      width: 100%;
      font-weight: 500;
    }
  }
  .landing {
    h1 {
      left: 10vw;
      letter-spacing: 0.5rem;
      line-height: 2.5rem;
    }

    p {
      bottom: 20vh;
      padding-left: 3rem;
    }

    img {
      margin-right: 5vw;
    }
  }

  .project {
    &__title {
      font-size: 3.5rem;
      padding-left: 5vh;
      transform-origin: center;

      &--container {
        height: 100vh;
        width: 25vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        transform: none;
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      width: 75vw;
    }

    &__image {
      margin: 0;
      max-height: max-content;
      justify-self: flex-start;
      flex: 3;

      &--bg {
        width: auto;
        height: 100%;
      }

      &--logo {
        height: auto;
        width: 80%;
      }
    }

    &__textBlock {
      font-size: 0.8rem;
      margin: 2.5vh 10% 0 0;
      max-width: 20rem;
      height: max-content;
      display: flex;
      flex-direction: column;
      padding-bottom: 2.5vh;
      // flex: 1;

      p {
        min-height: auto;
        overflow: scroll;
        margin: 0;
      }
    }

    &__links {
      flex: 0.6;
      a {
        font-size: 1rem;
      }
    }
  }

  .resume {
    display: flex;

    &__title {
      height: 100vh;
      width: 25vw;
      display: flex;
      justify-content: center;
      transform: none;
      align-self: flex-start;

      h1 {
        font: normal 400 3.5rem variables.$DMDisplay;
        transform-origin: center;
        transform: rotate(-90deg);
        height: max-content;
        align-self: flex-end;
        word-break: keep-all;
        text-transform: capitalize;
      }
    }

    &__download {
      display: none;
      a {
        font-size: 1.2rem;
      }
    }
    &__cred {
      flex-direction: column;
      overflow: scroll;
      padding: 0 0 0 1rem;
      // height: 100vh;
    }

    &__list {
      min-width: 50vw;
      margin-bottom: 1rem;

      h2 {
        font-size: 2.2vh;
        margin: 0 0 0.3rem 0;
      }

      ul {
        margin: 0;

        li {
          font-size: 1.8vh;
          margin: 0 0 0.2rem 0;
        }
      }
    }
    #downloadMobile {
      display: block;

      a {
        text-decoration: none;
        color: black;
        font-size: 1.8vh;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
@media only screen and (min-width: $breakpoint-mobile) and (max-width: $breakpoint-md) {
  .project {
    &__title {
      &--container {
        height: 5rem;
        width: 8rem;
      }
    }

    &__content {
      padding: 0;
    }

    &__image {
      margin: 4rem 0 4rem 3vw;
      max-height: 66vh;
      max-width: none;
      display: flex;
      justify-content: center;
      align-items: center;

      &--bg {
        height: 100vh;
      }
    }

    &__textBlock {
      flex: 1;
      margin: 0 auto 0 3vw;

      p {
        min-height: max-content;
      }
    }
  }
}
@media only screen and (min-width: $breakpoint-lg) {
  .project {
    &__image {
      max-height: 73vh;
      margin-left: 5rem;
    }

    &__textBlock {
      margin-left: 5rem;
    }
  }
}
