@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.rotating {
  -webkit-animation: rotating 10s linear infinite;
  -moz-animation: rotating 10s linear infinite;
  -ms-animation: rotating 10s linear infinite;
  -o-animation: rotating 10s linear infinite;
  animation: rotating 10s linear infinite;
}

html {
  background-color: #e5e5e5;
  padding: 0;
  margin: 0;
  scroll-snap-type: y mandatory;
  overflow-x: hidden;
}

.indicators {
  height: 8rem;
  width: max-content;
  position: -webkit-sticky;
  position: sticky;
  top: 2rem;
  left: 0;
  z-index: 100;
  transition: all 0.2s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.indicators:hover a {
  transition: all 0.2s ease;
  width: 100%;
}
.indicators__sections {
  display: flex;
  align-items: center;
}
.indicators__sections:hover a {
  margin: 0 0 0 1.2rem;
  font-weight: 700;
}
.indicators__sections a {
  transition: all 0.2s ease;
  width: 0rem;
  overflow: hidden;
  text-transform: capitalize;
  text-decoration: none;
  color: black;
}
.indicators__lines {
  background-color: black;
  height: 1px;
  width: 1.5rem;
  margin: 0 3px 0 0;
  pointer-events: none;
}

.landing {
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  scroll-snap-align: start;
}
.landing h1 {
  width: 8vh;
  font: normal 700 5vh "DM Serif Display", serif;
  text-transform: uppercase;
  position: absolute;
  top: 5vh;
  left: 40vw;
  z-index: 1;
}
.landing p {
  z-index: 1;
  font: normal 400 1.7vh "Lora", serif;
  position: absolute;
  bottom: 25vh;
  margin: auto;
  padding-left: 5vw;
  min-width: 15rem;
  max-width: 25vw;
}
.landing img {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}

.project__container {
  width: 100vw;
  position: relative;
  top: -8rem;
}
.project__section {
  width: 100vw;
  position: relative;
  display: flex;
  scroll-snap-align: start;
  scroll-snap-stop: always;
}
.project__content {
  flex-grow: 1;
  height: 100vh;
}
.project__title {
  font: normal 400 6rem "DM Serif Display", serif;
  transform-origin: 0 0;
  transform: rotate(-90deg);
  height: max-content;
  word-break: keep-all;
  width: 100vh;
  display: flex;
  margin: 0;
}
.project__title--container {
  transform: translateY(97vh);
  align-self: flex-start;
  width: 10rem;
  height: 8vh;
}
.project__image {
  margin: 5rem 5rem 4rem 0rem;
  overflow: hidden;
  max-height: 65vh;
  max-width: 85vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.project__image--bg {
  width: 100%;
  object-fit: cover;
}
.project__image--logo {
  position: absolute;
  height: 50%;
  z-index: 10;
}
.project__textBlock {
  max-width: 25rem;
  margin: 0 0 0 0rem;
}
.project__textBlock p {
  min-height: 7rem;
}
.project__techStacks {
  display: flex;
  flex-wrap: wrap;
  margin: 2vh 0 0 0;
}
.project__techStacks div {
  margin-right: 1rem;
  color: gray;
}
.project__links {
  display: flex;
  align-items: flex-end;
  text-align: center;
  padding: 2rem;
  flex: 0.8;
}
.project__links a {
  text-decoration: none;
  margin-right: 1rem;
  color: black;
  font: normal 700 1.2rem "Noto Serif", serif;
  text-transform: uppercase;
  flex: 1;
}
.project__links a:hover {
  text-decoration: underline;
}

.resume {
  height: 100vh;
  width: 100vw;
  scroll-snap-align: start;
}
.resume__title {
  display: none;
}
.resume__download {
  height: 70vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.resume__download a {
  margin: 0 1rem;
  text-decoration: none;
  color: black;
  font: normal 400 3rem "DM Serif Display", serif;
  text-transform: uppercase;
  letter-spacing: 1rem;
}
.resume__download div {
  height: 1px;
  width: 2rem;
  background-color: black;
}
.resume__download--icon {
  width: 2.1rem;
  height: 2.1rem;
}
.resume__cred {
  display: flex;
  justify-content: space-around;
}
.resume__list h2 {
  font: normal 600 1rem "Noto Serif", serif;
  text-transform: capitalize;
}
.resume__list ul {
  padding: 0;
}
.resume__list ul li {
  font: normal 400 0.8rem "Noto Serif", serif;
  text-transform: capitalize;
  list-style: none;
  text-align: left;
  margin-bottom: 0.5rem;
}
.resume__list ul li a {
  text-decoration: none;
  color: black;
}
.resume__list ul li a:hover {
  text-decoration: underline;
}
.resume #downloadMobile {
  display: none;
}

@media only screen and (max-width: 700px) {
  .indicators {
    top: 1rem;
  }
  .indicators .show {
    width: 100%;
    font-weight: 500;
  }

  .landing h1 {
    left: 10vw;
    letter-spacing: 0.5rem;
    line-height: 2.5rem;
  }
  .landing p {
    bottom: 20vh;
    padding-left: 3rem;
  }
  .landing img {
    margin-right: 5vw;
  }

  .project__title {
    font-size: 3.5rem;
    padding-left: 5vh;
    transform-origin: center;
  }
  .project__title--container {
    height: 100vh;
    width: 25vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: none;
  }
  .project__content {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 75vw;
  }
  .project__image {
    margin: 0;
    max-height: max-content;
    justify-self: flex-start;
    flex: 3;
  }
  .project__image--bg {
    width: auto;
    height: 100%;
  }
  .project__image--logo {
    height: auto;
    width: 80%;
  }
  .project__textBlock {
    font-size: 0.8rem;
    margin: 2.5vh 10% 0 0;
    max-width: 20rem;
    height: max-content;
    display: flex;
    flex-direction: column;
    padding-bottom: 2.5vh;
  }
  .project__textBlock p {
    min-height: auto;
    overflow: scroll;
    margin: 0;
  }
  .project__links {
    flex: 0.6;
  }
  .project__links a {
    font-size: 1rem;
  }

  .resume {
    display: flex;
  }
  .resume__title {
    height: 100vh;
    width: 25vw;
    display: flex;
    justify-content: center;
    transform: none;
    align-self: flex-start;
  }
  .resume__title h1 {
    font: normal 400 3.5rem "DM Serif Display", serif;
    transform-origin: center;
    transform: rotate(-90deg);
    height: max-content;
    align-self: flex-end;
    word-break: keep-all;
    text-transform: capitalize;
  }
  .resume__download {
    display: none;
  }
  .resume__download a {
    font-size: 1.2rem;
  }
  .resume__cred {
    flex-direction: column;
    overflow: scroll;
    padding: 0 0 0 1rem;
    height: 100vh;
  }
  .resume__list {
    min-width: 50vw;
    margin-bottom: 1rem;
  }
  .resume__list h2 {
    font-size: 2.2vh;
    margin: 0 0 0.3rem 0;
  }
  .resume__list ul {
    margin: 0;
  }
  .resume__list ul li {
    font-size: 1.8vh;
    margin: 0 0 0.2rem 0;
  }
  .resume #downloadMobile {
    display: block;
  }
  .resume #downloadMobile a {
    text-decoration: none;
    color: black;
    font-size: 1.8vh;
  }
  .resume #downloadMobile a:hover {
    text-decoration: underline;
  }
}
@media only screen and (min-width: 700px) and (max-width: 1500px) {
  .project__title--container {
    height: 5rem;
    width: 8rem;
  }
  .project__content {
    padding: 0;
  }
  .project__image {
    margin: 4rem 0 4rem 3vw;
    max-height: 66vh;
    max-width: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .project__image--bg {
    height: 100vh;
  }
  .project__textBlock {
    flex: 1;
    margin: 0 auto 0 3vw;
  }
  .project__textBlock p {
    min-height: max-content;
  }
}
@media only screen and (min-width: 2000px) {
  .project__image {
    max-height: 73vh;
    margin-left: 5rem;
  }
  .project__textBlock {
    margin-left: 5rem;
  }
}

